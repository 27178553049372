import {cookieDomain, cookiePrefix, mainUrl} from "./config";
import React, { Component } from 'react';

export const screenSizeLg = 1280;
export const screenSizeMd = 768;

/**
 * @returns {string}
 */
export function getScreenSize() {
    if (window.innerWidth >= screenSizeLg) return 'lg';
    if (window.innerWidth >= screenSizeMd) return 'md';
    return 'sm';
}

/**
 * @param cnt - items count
 * @param one - title for 1 item
 * @param some - title for 2 items
 * @param plural - title for plural items
 * @return string
 */
export function getWord(cnt, one, some, plural){
    switch (cnt % 10) {
        case 1:
            return (cnt % 100 === 11 ? plural : one);
        case 2:
        case 3:
        case 4:
            return ([11, 12, 13].indexOf(cnt % 100) !== -1 ? plural : some);
        default:
            return plural;
    }
}

export function getRandomInt(min, max){
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Method returned all categories from list
 * @param list List of items
 * @param catObj category Object
 * @param rankField string - only with field
 */
export function categoriesFromList (list, catObj, rankField = null) {

    let result = [];

    list.forEach(function (elem) {
        result[elem[catObj].id] = elem[catObj];
    });

    if (rankField) {
        result = result.filter(c => !!c[rankField]);
    } else {
        result = result.filter(c => c);
    }

    return result;
}

/**
 * Sort Array by property
 * @param list array to sort
 * @param prop Property for sorting
 * @param newArr boolean
 * @returns Array
 */
export function sortByProp (list, prop, newArr = false) {

    let arr = [];

    if (newArr) {
        arr = list.slice(0);
    }else {
        arr = list;
    }

    arr.sort(function (elemA, elemB) {
        return elemB[prop] - elemA[prop];
    });

    return arr;
}

export function rand (max, old = -1) {

    let rand = Math.floor(Math.random() * max);

    if (rand === old) {
        rand = this.rand(max, old);
    }

    return rand;
}

export function getMeasure (str, idx) {
    let arr = str.split('/');
    return arr[idx] || "";
}

/**
 * Method return coords of element
 * @param eID
 * @returns {{top: number, left: number}}
 */
function getCoords(eID) { // кроме IE8-
    let elem = document.getElementById(eID);
    let box = elem.getBoundingClientRect();

    return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset,
        right: box.right + pageYOffset,
        bottom: box.bottom + pageYOffset
    };
}

function currentYPosition() {
    if (self.pageYOffset) return self.pageYOffset;
    if (document.documentElement && document.documentElement.scrollTop)
        return document.documentElement.scrollTop;
    if (document.body.scrollTop) return document.body.scrollTop;
    return 0;
}

export function smoothScroll(eID, angle = 'top') {
    let startY = currentYPosition();
    let stopY = getCoords(eID)[angle];
    let distance = stopY > startY ? stopY - startY : startY - stopY;
    if (distance < 100) {
        scrollTo(0, stopY); return;
    }
    let speed = Math.round(distance / 100);
    if (speed >= 20) speed = 20;
    let step = Math.round(distance / 25);
    let leapY = stopY > startY ? startY + step : startY - step;
    let timer = 0;
    if (stopY > startY) {
        for ( let i=startY; i<stopY; i+=step ) {
            setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
            leapY += step; if (leapY > stopY) leapY = stopY; timer++;
        } return;
    }
    for ( let i=startY; i>stopY; i-=step ) {
        setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
        leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
    }
}

export function filterServices (services, field) {
    return services.filter(x => x.mainCategory[field]);
}

export function setCookie(name, value, options, noPref = false) {
    let nameFn = '';
    if (!!noPref) {
        nameFn = name;
    } else {
        nameFn = cookiePrefix+"_"+name;
    }
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = nameFn + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

export function getCookie(name, noPref = false) {
    let nameFn = '';
    if (!!noPref) {
        nameFn = name;
    } else {
        nameFn = cookiePrefix+"_"+name;
    }
    if (typeof document === "undefined") return undefined;

    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + nameFn.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));

    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name, noPref = false) {
    // let nameFn = '';
    // if (!!noPref) {
    //     nameFn = name;
    // } else {
    //     nameFn = cookiePrefix+"_"+name;
    // }

    setCookie(name, "", {
        expires: -1,
        path: '/',
        // domain: window.location.hostname
        domain: cookieDomain
    }, noPref)
}

export function normalizeForSelect (data, field = 'value') {
    data = data.filter(item => item[field] !== "");

    return data.map(function (item) {
        return {
            label: item[field],
            value: item[field]
        }
    })
}

export function getDomainFromSubdomain () {
    // if (typeof window !== "undefined") {
    //     let domain = window.location.hostname;
    //     domain = domain.substr(domain.indexOf('.') + 1, domain.length);
    //     return domain;
    // }
    return mainUrl.substr(mainUrl.indexOf('//') + 2, mainUrl.length - mainUrl.indexOf('//') - 3)
}

export function getDomain () {
    return window.location.hostname;
}

export function setRegionCookie (regionAlias) {

    let date = new Date;
    date.setDate(date.getDate() + 365);

    setCookie('regionName', regionAlias, {
        path: '/',
        domain: cookieDomain,
        expires: date
    });
}

export function toFixedNum(num, fixedNumbers = 0, withNull = false) {
    let parseNum = parseFloat(num),
        result = 0;

    if (!((parseNum ^ 0) === parseNum)) {
        if (withNull) {
            result = parseNum.toFixed(fixedNumbers);
        } else {
            if (parseNum.toString().split('.').pop().length > fixedNumbers) {
                result = parseNum.toFixed(fixedNumbers);
            } else {
                result = parseNum;
            }
        }
    } else {
        if (withNull) {
            result = parseNum.toFixed(fixedNumbers);
        } else {
            result = parseNum;
        }
    }

    return result;
}

export function prepareDescription(description, region) {
    let matches = description.match(/(href="(.*?)"|href='(.*?)')/g)
    if (!matches) return description;
    matches = matches.map((match) => {
        return match.substr(6, match.length - 7)
    });

    let templates = [], replacements = [];
    matches.map((match) => {
        if (match.indexOf('http') !== 0) {
            templates.push(match);
            replacements.push(mainUrl+region+match);
        }
    });

    return description.replace(templates, replacements);
}

export function getTimezone() {
    return -1*((new Date()).getTimezoneOffset()/60);
}

// random selection of 'n' array elements
export function getNelementsArray (arr, n){
    let result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        let x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

export function getRounding (minInterval, maxInterval, roundingHigh){
    let result = Math.floor(minInterval + roundingHigh * (maxInterval - minInterval) / 100);
    return result
}

export function setIdButton (regionId, assemblyType = false, tarifName = false, connectionType = false, typeButton){
    let idButton = '';
    let varRegion = null;

    if (+regionId === 66) varRegion = 'svd';
    if (+regionId === 45) varRegion = 'kgn';
    if (+regionId === 86) varRegion = 'khmao';
    if (+regionId === 89) varRegion = 'yanao';


    idButton = varRegion + '-' + (!!assemblyType ? (assemblyType + '-') : '') + (!!tarifName ? (tarifName + '-') : '') + (!!connectionType ? (connectionType + '-') : '') + typeButton;

   return idButton
}

export function isEmptyObj (obj) {
    return !Object.keys(obj).length > 0;
}

export function setScale (items) {
    let m = '';
    let gb = '';

    if (items.minutes_min !== '' && items.minutes_max !== '') {
        m = setItem('min', items.minutes_min, items.minutes_max);
    } else {
        m = setItem('min', 0, 1500);
    }

    if (items.data_min !== '' && items.data_max !== '') {
        gb = setItem('gb', items.data_min, items.data_max);
    } else {
        gb = setItem('gb', 1, 70);
    }

    function setItem (type, min, max) {
        let data = '';
        for (let i = min; i <= max; type === 'gb' ? i += 1 : i += 10) {
            data += i;
            if (i < max) data += ','
            if (type === 'gb' && i === max) data += ',unlim';
        }
        return data;
    }

    return {m, gb};
}

export function chekParams (arr, findItem) {
    if ( arr && arr.length > 0 && arr.find(x => x === findItem)) {
        return 1
    }
    return 0
}

export function checkAllUrlParams () {
    let pathnameStr = location.search !== '' && location.search.search(/svoi=/i) !== -1 ? location.search.substr(1).split('svoi=') : false;
    let urlParamsSvoi = !!pathnameStr ? pathnameStr[1].substr(0).split('&') : false;
    let urlParams = !!urlParamsSvoi ? urlParamsSvoi[0].substr(0).split('-') : false;

    if (!!urlParams && urlParams.length === 8) {
        return urlParams;
    }
    return false;
}

export function checkUtmParams() {
    const str = 'нет';
    let searchParam = window.location.search !== '' && window.location.search.search(/utm_source=/i) !== -1 ? window.location.search.substr(1).split('utm_source=') : false;

    if (!!searchParam) {
        return searchParam[1]
    }
    return str
}

export function getDateExpires(days) {
    //days количество суток
    let date = new Date();
    date.setDate(date.getDate() + days);
    return date;
}

export function setUtmCookie(days, nameCookie, value) {
    setCookie(nameCookie, value, {
        path: '/',
        expires: getDateExpires(days),
        domain: cookieDomain
    });
}

export function renderActiveField(data, type, customClassName = false) {
    const decimalPoint = /[, ]/g;
    const semicolon = /[; ]/g;
    let splitParam = null;
    let result = null;

    if (!!data) {
        if (data.search(decimalPoint) !== -1) {
            splitParam = ', ';
        }
        if (data.search(semicolon) !== -1) {
            splitParam = '; ';
        }

        const items = data.split(splitParam);
        let typeLink = 'tel:';
        if (type === 'email') typeLink = 'mailto:';
        if (!!splitParam) {
            result = items.map((el, key) =>
                <a
                    key={key}
                    href={`${typeLink}${el}`}
                    className={(customClassName ? customClassName : 'legal-information-page__requisites-item-value ') + (type === 'email' ? ' link' : '')}>
                        {el};&nbsp;
                </a>
            )
        } else {
            result = <a
                href={`${typeLink}${data}`}
                className={(customClassName ? customClassName : 'legal-information-page__requisites-item-value ') + (type === 'email' ? ' link' : '')}>
                    {data}
                </a>
        }
        return result
    }
}

// устанавливаем квери параметр в адресную строку
export function setQueryParam(name, data) {
    const url = new URL(document.location);
    url.searchParams.set(name, data);
    window.history.pushState({}, '', url.toString());
}

// удаляем квери параметр из адресной строки
export function deleteQueryParam(name) {
    const url = new URL(document.location);
    url.searchParams.delete(name);
    window.history.pushState({}, '', url.toString());
}

// получаем квери параметр из адресной строки
export function getQueryParam(name) {
    const url = new URL(document.location);
    return url.searchParams.get(name);
}

// получаем имя квери параметра из адресной строки
export function hasQueryParam(name) {
    const url = new URL(document.location);
    return url.searchParams.has(name);
}

// склонение слов
// n --- принимет число
// text_forms --- принимает массив к примеру ['магазин', 'магазина', 'магазинов']
export function declOfNum(n, text_forms) {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 == 1) { return text_forms[0]; }
    return text_forms[2];
}

// функция которая подсвечивает найденные символы
export function insertMark(text = '', inputValue = '') {
    // что бы не было краша, экранируем спецсимволы, к примеру если искать *104*102#, то без экранирования все крашится
    const escapingCharacters = inputValue.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
    const parts = text.split(new RegExp(`(${escapingCharacters})`, 'gi'));

    return (
      <span> 
        { parts.map((part, i) => 
          <span
            key={i}
            style={part.toLowerCase() === inputValue.toLowerCase() ? { background: '#f37021', color: '#000' } : {} }
            dangerouslySetInnerHTML={{ __html: part }}
          />)}
      </span>
    )
  }


// функция которая ищет совпадение в начале строки из двух символов число и буква или буква и число
export function findTwoSimbols(str) {
    let reg = /^(((\d[а-яА-ЯЁёa-zA-Z]){1})|(([а-яА-ЯЁёa-zA-Z]\d){1}))$/u;
    return reg.test(str.toLowerCase());
}

// добавляет скобки в номер телефона
export function addBrackets(str) {
    const splitStr = str.split('');
    if (str.indexOf('+') !== -1) {
        splitStr && splitStr.splice(3, 0, '(');
        splitStr && splitStr.splice(7, 0, ')');
    } 
    if (str.indexOf('+') === -1) {
        splitStr && splitStr.splice(0, 0, '+', '7', ' ');
        splitStr && splitStr.splice(3, 0, '(');
        splitStr && splitStr.splice(7, 0, ')', ' ');
        splitStr && splitStr.splice(12, 0, ' ');
        splitStr && splitStr.splice(15, 0, ' ');
    }
    return splitStr.join('');
}

/**
 * изменяет цвет числа
 * @param val текущее значение инпутренджа
 * @param min минимальное значение для инпут ренджа
 * @param max максимальное значение для инпут ренджа
 */
export function colorNum(val, min, max) {
    if (!min || !max || (min == max) || min == 'undefined' || max == 'undefined' || val == 'undefined') {
        return null;
    }

    const elem = document.querySelector('.b-input-text__wrapper input');

    const rangeMaxMin = max - min;
    const percent10 = rangeMaxMin / 100 * 10;
    const percent20 = rangeMaxMin / 100 * 20;
    const percent30 = rangeMaxMin / 100 * 30;
    const percent40 = rangeMaxMin / 100 * 40;
    const percent50 = rangeMaxMin / 100 * 50;
    const percent60 = rangeMaxMin / 100 * 60;
    const percent70 = rangeMaxMin / 100 * 70;
    const percent80 = rangeMaxMin / 100 * 80;
    const percent90 = rangeMaxMin / 100 * 90;
    const percent100 = rangeMaxMin / 100 * 100;
    
    if ((val < min + percent10)) elem.style.color = '#000';
    if (((val >= (min + percent10)) && (val < (min + percent20)))) elem.style.color = '#2d1502';
    if (((val >= (min + percent20)) && (val < (min + percent30)))) elem.style.color = '#431f03';
    if (((val >= (min + percent30)) && (val < (min + percent40)))) elem.style.color = '#5a2a04';
    if (((val >= (min + percent40)) && (val < (min + percent50)))) elem.style.color = '#703405';
    if (((val >= (min + percent50)) && (val < (min + percent60)))) elem.style.color = '#873e06';
    if (((val >= (min + percent60)) && (val < (min + percent70)))) elem.style.color = '#9d4907';
    if (((val >= (min + percent70)) && (val < (min + percent80)))) elem.style.color = '#b45308';
    if (((val >= (min + percent80)) && (val < (min + percent90)))) elem.style.color = '#ca5e09';
    if (((val >= (min + percent90)) && (val < (min + percent100)))) elem.style.color = '#e1680a';
    if (val >= (min + percent100)) elem.style.color = '#f37021';
}


// /**
//  * получить координаты указанного города
//  * @param cityId id города
//  */
// export function getBoundsByCity(cityId) {
//     const cities = {
//         "45": [55.45876590, 65.33174700], // "Курганская область"
//         "86": [61.0090919, 69.0374596], // "Ханты-Мансийский автономный округ - Югра"
//         "66": [56.833333, 60.583333], // "Свердловская область"
//         "89": [67.25, 74.666667], // "Ямало-Ненецкий автономный округ"
//     }
//     return cities[cityId]
// }

  