/**
 *  Copyright 2018 Avaya Inc. All Rights Reserved.
 *
 * Usage of this source is bound to the terms described in
 * licences/License.txt
 *
 * Avaya - Confidential & Proprietary. Use pursuant to your signed agreement or
 * Avaya Policy
 *
 *
 * Defines the functionality for the logon window
 */

//***
// var аreasAllData = window.REDUX_DATA.chatFormFunc.chatFormItems.params && window.REDUX_DATA.chatFormFunc.chatFormItems.params.responsibility_areas ? window.REDUX_DATA.chatFormFunc.chatFormItems.params.responsibility_areas : '';
// var usedArea = "Test.Test";
// var mainZone = аreasAllData.find(x => x.is_main === true);

// var currentArea = JSON.parse(localStorage.getItem("currentArea"));

// if (!!currentArea) {
//     usedArea = currentArea;
// } else {
//     usedArea = mainZone.name;
// };
//***

window.chatLogon = {

    /**
    * The attributes for the chat. There is a known issue where Work Assignment does not accept any contact
    * whose contextId has more than 10 attributes associated with it. Those attributes include this array *AND*
    */

    // *** the "attributes" parameter is passed in the AvayaChat.js component
    // attributes : usedArea,

    // workaround for ED having issues with more than 10 attributes per context ID.
    attributeCount : 1,
    maxAttributes : 10,
    userGuest: null,


    /**
     * Logs the customer into the chat.
     * @param {JSON} custDetails - JSON object that wraps up the customer details
     */
    logon : function(custDetails) {
        'use strict';
        chatUI.changeToChatMode();
        webChat.initChat(true, custDetails);
    },

    /**
     * Check the email validity while the customer is typing. Called from the email type input itself, using an oninput event.
     * @param {HTMLElement} el - the email input that calls this method.
     */
    checkEmailValidity: function(el) {
        'use strict';
        var isEmailValid = el.checkValidity() && el.value.trim().length > 0;
        chatUI.setEmailInputValid(isEmailValid);
    },

    /**
     * Validate phone details
     * @param {Object} country
     * @param {Object} area
     * @param {Object} phone
     * @returns {Boolean} - true if the details are valid
     */
    // validatePhone : function(country, area, phone) {
    //     'use strict';

    //     // Allow blank if no phone number required
    //     if (country.value + area.value + phone.value === '' && !chatConfig.requirePhone){
    //         return true;
    //     } else {
    //         // Check if the phone number is valid
    //         // Country codes are optional to facilitate lab testing with internal numbers
    //         // Some countries (e.g. Singapore) do not use area codes, so these are optional as well
    //         var reCountry = new RegExp("^("+country.pattern+"|)$");
    //         var reArea = new RegExp("^("+area.pattern + "|)$");
    //         var rePhone = new RegExp("^"+phone.pattern+"$");

    //         return (reCountry.test(country.value) && reArea.test(area.value) && rePhone.test(phone.value));
    //     }
    // },
    validatePhone : function(phone) {
        'use strict';

        // Allow blank if no phone number required
        if (phone.value === '' && !chatConfig.requirePhone){
            return true;
        } else {
            // Check if the phone number is valid
            // Country codes are optional to facilitate lab testing with internal numbers
            // Some countries (e.g. Singapore) do not use area codes, so these are optional as well
            var rePhone = new RegExp("^"+phone.pattern+"$");

            return (rePhone.test(phone.value));
            console.log("FALSE PHONE");

        }
    },

    /**
     * Gathers the customer's details before logging them in.
     */
    gatherDetails : function(easyEntry) {
        'use strict';
        var isStringEmpty = avayaGlobal.isStringEmpty;
        var details = chatUI.getCustomerDetails(easyEntry);
        var l_user = details.firstName, l_user_last = details.lastName, l_email = details.email, l_message = details.message;
        var phoneDetails = chatUI.getPhoneDetails(easyEntry);

        // gather custom fields
        var customFields = chatUI.getCustomFields(easyEntry);
        var customFieldTitle = customFields.title;
        var customFieldValue = customFields.value;

        var errors = '';

        if (chatConfig.requireEmail && isStringEmpty(l_email)) {
            errors += "A valid email address is required\n";
        }

        // validate the phone detaiols here
        if (!easyEntry && !chatLogon.validatePhone(phoneDetails.phone)) {
            errors += 'A valid phone number is required\n';
        }

        // check if the name is too long
        if (l_user.length > 50) {
            errors += 'Your name is too long. It must be less than 50 characters\n';
        }
        else if (chatConfig.requireFirstName && isStringEmpty(l_user)) {
            errors += "You must provide a first name and last name\n";
        }

        // if (l_user_last.length > 100) {
        //     errors += 'Your surname is too long. It must be less than 100 characters\n';
        // }
        // else if (chatConfig.requireLastName && isStringEmpty(l_user_last)) {
        //     errors += "You must provide a first name and last name\n";
        // }

        if (customFieldTitle && customFieldTitle.length > 50) {
            errors += "Custom Fields titles must be less than 50 characters\n";
        }
        if (customFieldValue && customFieldValue.length > 255) {
            errors += "Custom Fields values must be less than 255 characters\n";
        }
        if (isStringEmpty(customFieldTitle) && ! isStringEmpty(customFieldValue)) {
            errors += "A custom field cannot have an empty title\n";
        }

        if (errors === '') {

            // do not add empty custom fields
            if (!isStringEmpty(customFieldTitle)) {
                webChat.addCustomFields(customFieldTitle, customFieldValue);
            }

            var custDetails = {
                "firstName" : l_user,
                "lastName" : l_user_last,
                "email" : l_email,
                "message": l_message,
                "phone" : {
                    "number" : phoneDetails.phone.value
                }
            };

            // save these details for if we need to reload the page
            avayaGlobal.setSessionStorage("custDetails", JSON.stringify(custDetails));
            chatLogon.logon(custDetails);
        } else {
            var message = "Please correct the following errors:\n" + errors;
            chatUI.showLoginErrors(message);
        }

    },

    /**
     * Add an attribute to the chat logon attributes. This does <em>not</em> affect Context Store.
     * @param newAttribute is a String that represents the attribute.
     */
    addAttribute : function(newAttribute) {
        'use strict';
        if (chatLogon.attributes.indexOf(newAttribute) < 0 && chatLogon.attributeCount < chatLogon.maxAttributes) {
            chatLogon.attributes.push(newAttribute);
            chatLogon.attributeCount++;
            chatLogon.saveAttributeCount();
        }
    },

    /**
     * Removes the specified attribute from the chat attributes. This does <em>not</em> affect Context Store.
     * @param oldAttribute is a String that represents the attribute to remove.
     */
    removeAttribute : function(oldAttribute) {
        'use strict';
        var index = chatLogon.attributes.indexOf(oldAttribute);
        chatLogon.attributes.splice(index, 1);
        if (chatLogon.attributeCount > 0) {
            chatLogon.attributeCount--;
            chatLogon.saveAttributeCount();
        }
    },

    /**
     * Save the total attribute count. Part of a workaround for OCPROVIDER-1206
     */
    saveAttributeCount : function() {
        'use strict';
        avayaGlobal.setSessionStorage('chatAttrCount', chatLogon.attributeCount);
    },

    loadAttributeCount : function() {
        'use strict';
        var attr = avayaGlobal.getSessionStorage('totalAttributes');
        chatLogon.attributeCount = attr !== null ? parseInt(attr) : 0;
    }
};