/**
 *  Copyright 2018 Avaya Inc. All Rights Reserved.
 *
 * Usage of this source is bound to the terms described in
 * licences/License.txt
 *
 * Avaya - Confidential & Proprietary. Use pursuant to your signed agreement or
 * Avaya Policy
 *
 * Handles the UI side of the web chat library, which is based around jQuery UI. Replace the following with your own UI code, if you prefer.
 */
import {isProduction} from "../../js/tools/config"

window.chatUI = {

    /**
     * Hide the chat panel.
     */
    hideChatPanel : function() {
        'use strict';
        // $('#liveChatLink').hide();
    },

    /**
     * Show the chat panel.
     */
    showChatPanel : function() {
        'use strict';
        $('#liveChatLink').show();
    },

    /**
     * Change the textContent of the specified element.
     * @param el
     * @param newText
     */
    changeElementText : function(el, newText) {
        'use strict';
        $(el).text(newText);
    },

    /**
     * Add the EWT to the chat tab. Included as an alternative to using an alert window.
     */
    addEwtToChatTab : function(waitTime) {
        'use strict';
        // $('#liveChatLink').attr('title',
        //         'Нажмите на кнопку чата, чтобы пообщаться с оператором! Время ожидания примерно ' + waitTime + ' минут');
    },

    /**
     * Changes the chat panel to welcome mode.
     */

    /**
     * Changes the chat panel to chat mode.
     */
    changeToChatMode : function() {
        'use strict';
        var isiPhone = navigator.userAgent.match(/iPhone/i) != null;

        $('#chatForm').hide();
        $('#chatWelcomeForm').hide();
        $('.chat-welcome-alert').hide();
        $('.chat-welcome-msg-position').hide();

        $('.chat-welcome-msg-wrap').hide();
        $('.welcome-form-btn-wrap').hide();
        $("#chatInterface").addClass("chat-interface__show")
        $('#chatInterface').show();
        $('#chatPanel').dialog({
            width : 404,
            'resize' : 'auto',
            dialogClass : 'fixedPosition chatPosition communicationForm'
        });

        $('#chatPanel').dialog('widget').attr('id', 'chatPanelHidden');
        if (isiPhone) {
            $("#chatInterface").addClass("chat-interface-iClass");
            $("#chatPanelHidden").addClass("chat-panelHidden-iClass");
        }
        avayaGlobal.removeFromSessionStorage("custDataOpened");
    },

    /**
     * Changes the chat panel to login mode.
     */
    changeToLoginMode : function() {
        'use strict';
        var isiPhone = navigator.userAgent.match(/iPhone/i) != null;
        $('#chatInterface').hide();
        $("#chatInterface").removeClass("chat-interface__show")
        $('#chatWelcomeForm').hide();
        $('.chat-welcome-alert').hide();
        $('.chat-welcome-msg-position').show();
        $('.chat-welcome-msg-wrap').show();
        $('.welcome-form-btn-wrap').show();
        $('#chatForm').show();
        if (isiPhone) {
            $("#chatForm").addClass("chat-form-iClass");
        }
        $('#chatPanel').dialog({
            width : 400,
            'resize' : 'auto',
            dialogClass : 'fixedPosition'
        });
        avayaGlobal.setSessionStorage("custDataOpened", 1);
    },

    resetChatUI : function() {
        'use strict';
        chatUI.changeToLoginMode();
        $('#chatPanel').dialog('close');
        $('#liveChatLink').show();
        $("#outmessage").val("");
        $("#messages").empty();
        avayaGlobal.removeFromSessionStorage("custDataOpened");
    },

    resizeConfigPanel : function() {
        'use strict';
        $('#configPanel').dialog({
            width : 400,
            height : 350
        });
    },

    /**
     * Create and show an alert
     * @param {string} msg
     */
    showAlert : function(msg) {
        'use strict';
        $('#alertDialog').dialog({
            draggable : false,
            resizeable : false,
            buttons : {
                OK : function() {
                    $(this).dialog('close');
                }
            }
        }).text(msg);
    },

    chatAlert: function() {
        "use strict";
        $(".welcome-form-btn-wrap").addClass("welcome-form-disabled")
        $(".chat-welcome-msg-wrap").addClass("chat-welcome-msg--alert-wrap")
        $('.chat-welcome-msg-position').hide();
        $('.chat-welcome-alert').show();
    },

    chatWelcomeOpen: function() {
        "use strict";
        $(".welcome-form-btn-wrap").removeClass("welcome-form-disabled")
        $(".chat-welcome-msg-wrap").removeClass("chat-welcome-msg--alert-wrap")
        $('.chat-welcome-alert').hide();
        $('#chatWelcomeForm').show();
        $('.chat-welcome-msg-position').show();
        $('.chat-welcome-msg-wrap').show();
        $('.welcome-form-btn-wrap').show();
    },

    showLoginErrors: function(msg) {
        "use strict";
        $('#loginErrorsDiv').show("fold");
        $('#loginErrorsDiv').text(msg);
        setTimeout(function(){
            $('#loginErrorsDiv').hide("fold");
        }, 2000);
    },

    markElAsRequired: function(id, isRequired){
        "use strict";

        var text = $(id).text();
        var newText;
        // console.log(text);

        if (isRequired) {
            newText = text;
        } else {
            newText = text.replace("");
        }

        $(id).text(newText);
    },

    /**
     * Shake the chat tab slightly to draw attention to it.
     */
    shakeChatTab : function() {
        'use strict';
        var shakes = 0, maxShakes = 6;
        var dir = 1;
        window.tabShakeInterval = setInterval(function() {
            shakes++;
            // $('#liveChatLink').css('right', dir * 4);
            dir *= -1;

            if (shakes >= maxShakes) {
                clearTimeout(window.tabShakeInterval);
            }
        }, 1000);
    },

    closePanel : function(event) {
        'use strict';
        event.preventDefault();
        console.debug("WebChat: closing chat panel");

        // when the chat dialog is closed, reset things
        $('#liveChatLink').show();
        chatSocket.clearRefresh();
        chatSocket.manualClose = true;
        clearTimeout(chatSocket.closeTimer);
        if (webSocket.readyState !== WebSocket.CLOSING) {
            chatConfig.dontRetryConnection = true;
            webChat.quitChat();
        }

        avayaGlobal.removeFromSessionStorage("custDataOpened");
    },

    /**
     * Sets up the UI code.
     */
    setup : function() {
        'use strict';
        // set up the link for liveChat - this is what the user clicks to open the chat
        $('#liveChatLink').click(function(event) {
            event.preventDefault();
            var attrStatus = $('#liveChatLink').attr('status');

            if ( attrStatus === 'close' ) {
                //Close form chat

                $(document.body).addClass('no-scroll-body-mobile');
                $('#chatForm').hide();
                if (  window.webSocket !== undefined && (  window.webSocket.readyState !==  window.WebSocket.CLOSED)) {
                    $('#chatWelcomeForm').hide();

                } else {
                    $('#chatWelcomeForm').show();
                }
                // $('.chat-welcome-msg-position').show();

                $('#chatPanel').dialog({
                    width : 404,
                    height : 'auto',
                    dialogClass : 'fixedPosition chatPosition communicationForm welcome-form-wrap',
                }).on('dialogclose', function(event) {
                    if (window.webSocket !== undefined ) {
                        // ***disabled closing an active chat session
                        // chatUI.closePanel(event);
                    }
                });
            }

            if ( attrStatus === 'open' ) {
                //Open form chat
                event.preventDefault();
                $('#chatPanel').dialog('close');
                $(document.body).removeClass('no-scroll-body-mobile');
            }
        });

        $('#goToAuth').click(function(event) {
            event.preventDefault();
            var isiPhone = navigator.userAgent.match(/iPhone/i) != null;
            // $('#liveChatLink').hide();
            var elemGoToAuth = $('#goToAuth').attr('blocking');
            if (elemGoToAuth === 'false') {
                $('#chatForm').show();
                if (isiPhone) {
                    $("#chatForm").addClass("chat-form-iClass");
                }
                $('#chatWelcomeForm').hide();
                $('#chatPanel').dialog({
                    width : 404,
                    height : 'auto',
                    dialogClass : 'fixedPosition chatPosition communicationForm personalForm authFormChat',
                }).on('dialogclose', function(event) {
                    if (window.webSocket !== undefined ) {
                        // "closePanel" close the form on click
                        // chatUI.closePanel(event);
                    }
                });
                avayaGlobal.setSessionStorage("custDataOpened", 1)
            }
        });

        // gives the liveChatLink a fancier tooltip
        $('#liveChatLink').tooltip();

        $('#configLink').click(function(event) {
            $('#configPanel').dialog({
                width : 400,
                height : 350,
                'resize' : 'auto',
                dialogClass : 'fixedPosition'
            });
            event.preventDefault();
        });

        $('#showCoBrowseLink').click(function(event) {
            if (!coBrowseUI.checkDialogOpen(coBrowseUI.proactiveJoinDialogId)) {
                coBrowseUI.createProactiveJoinDialog(true);
            }
            event.preventDefault();
        });

        // Set jQuery UI button
        $('.button').button();

        // hide the alert header and shake the chat tab
        $('#chatJavaScriptAlertHeader').hide();
        chatUI.shakeChatTab();

        chatUI.markElAsRequired("#nameLabel", chatConfig.requireFirstName);
        chatUI.markElAsRequired("#nameLabel", chatConfig.requireFirstName || chatConfig.requireLastName);
        chatUI.markElAsRequired("#message", chatConfig.requireMessage);
        chatUI.markElAsRequired("#emailLabel", chatConfig.requireEmail);
        chatUI.markElAsRequired("#phoneLabel", chatConfig.requirePhone);

        $("#outmessage").on("keydown", webChat.onType);
    },

    /**
     * Reload the chat panel after a refresh
     */
    reloadChatPanel: function() {
        'use strict';
        console.log("Reloading chat panel");
        chatUI.changeToChatMode();

        // I honestly don't know WHY this line is required, but it's the only thing that works.
        $("#liveChatLink").click();
    },

    /**
     * Returns the values of the phone inputs, and patterns defined on them for validation.
     * @returns {chatUI.getPhoneDetails.webChatUIAnonym$6}
     */
    getPhoneDetails: function(easyEntry) {
        "use strict";
        var phoneValue = $("#phone-chat").val().replace(/\D/gim, '').slice(1);

        return {
            // "country" : {
            //     "value" : $("#phone-country").val(),
            //     "pattern" : $("#phone-country").prop("pattern")
            // },
            // "area" : {
            //     "value" : $("#phone-area").val(),
            //     "pattern" : $("#phone-area").prop("pattern")
            // },
            "phone" : {
                "value" : !!easyEntry ? '' : phoneValue,
                "pattern" : !!easyEntry ? '' : $("#phone-chat").prop("pattern")
            }
        };
    },

    /**
     * Gather customer details from the page. Phone details are added separately so they can be validated.
     * @returns {Object} a JSON object containing entries from the page
     */
    getCustomerDetails: function(easyEntry) {
        "use strict";
        return {
            "firstName" : !!easyEntry ? window.chatConfig.defaultUsername : $("#user-chat").val(),
            "lastName" : !!easyEntry ? null : $("#user-chat-last").val(),
            "message" : !!easyEntry ? '' : $("#message").val(),
            "email" : !!easyEntry ? '' : $("#email-chat").val()
        };
    },

    getChatMessage: function(){
        "use strict";
        return $("#outmessage").val();
    },

    /**
     * Disable the chatbox and sendbutton.
     * @param {Boolean} isDisabled - if true, disable the controls.
     */
    disableControls: function(isDisabled) {
        "use strict";
        $("#outmessage").attr("disabled", isDisabled);
        $("#sendbutton-chat").attr("disabled", isDisabled);
    },

    /**
     * Append a paragraph or other element to the chat transcript.
     * Includes an autoscroll mechanism
     * @param {HTMLElement} para
     */
    appendParagraph: function(para) {
        "use strict";
        $("#messages").append(para);
        $("#messages").scrollTop($("#messages").prop("scrollHeight"));
    },

    /**
     * Clears the chatbox.
     */
    clearMessageInput: function() {
        "use strict";
        $("#outmessage").val("");
    },

    getTranscriptElements: function() {
        "use strict";
        return $("#messages").children();
    },

    getCustomFields: function(easyEntry) {
        "use strict";
        // var siteUrl = window && window.location.href;
        var siteUrl = window && (window.location.origin+window.location.pathname);

        var ourNumber = null;
        var userGuest = window.chatLogon.userGuest;
        var logonHandle = userGuest ? null : window.chatConfig.logonHandleUser;
        var isCallback = false;
        var phone = !!easyEntry ? null : $("#phone-chat").val() !== "" ? $("#phone-chat").val().replace(/\D/gim, '').slice(1) : null;
        var reservedField1 = null;

        var valueStr = {
            siteUrl: siteUrl,
            ourNumber: ourNumber,
            isProduction: !!isProduction ? true : false,
            isAuthorized: !userGuest,
            logonHandle: logonHandle,
            isCallback: isCallback,
            phone: phone,
            reservedField1: reservedField1
        };

        return {
            "title" : "siteData",
            "value" : JSON.stringify(valueStr)
        };

    },

    getTopic: function() {
        "use strict";
        return $("#topic").val().replace(/[ #~?@$\s]/g, "");
    },

    getRequestTranscript: function() {
        "use strict";
        return $("#transcript-chat").prop("checked");
    },

    /**
     * Toggles whether or not the email is considered valid by the browser.
     * If not, disable and uncheck the "request transcript" checkbox.
     * @param {Boolean} isValid
     */
    setEmailInputValid: function(isValid) {
        "use strict";
        if (!isValid) {
            $("#transcript-chat").prop("disabled", true);
            $("#transcript-chat").prop("checked", false);
        } else {
            $("#transcript-chat").prop("disabled", false);
        }
    },

    clearUsers: function() {
        "use strict";
        $("#participants > div").children("img").attr("src", "");
        $("#participants > div").children("img").attr("class", "hidden");
        var images = $("#participants > .hidden").children("img").toArray();
        for (var i = 0; i < images.length; i++) {
            images[i].nextSibling.textContent = "";
        }
    },

    updateUserImage: function(index, imageSrc, className, displayName) {
        "use strict";
        var image = $("#participants > div > img")[index];
        image.src = imageSrc;
        image.className = className;
        image.nextSibling.textContent = displayName;
    }

};
