/**
 *  Copyright 2018 Avaya Inc. All Rights Reserved.
 *
 * Usage of this source is bound to the terms described in
 * licences/License.txt
 *
 * Avaya - Confidential & Proprietary. Use pursuant to your signed agreement or
 * Avaya Policy
 *
 * Defines the client end of the WebSocket chat.
 */



window.webChat = {

    users : {},
    chatBotName : '',

    guid : null,
    ak : null,
    webOnHoldComfortGroups : null,
    webOnHoldURLs : null,

    onHoldUrlInterval : null,
    onHoldComfortInterval : null,
    pingInterval : null,

    // isTyping variable currently not used, Planned for future release
    isTyping : false,
    typeOut : null,
    activeAgentTypeOut : null,
    passiveAgentTypeOut : null,
    supervisorTypeOut : null,
    usersTable : null,
    lastIsTypingSent : 0,
    timeBetweenMsgs : 10000,

    initCalled : false,
    autoOpen : false,

    timeouts : [],

    // holds details such as names, etc.
    custDetails : {},

    customFields: [],

    // custom data for chatbot
    customData: {},

    workflowType : '',
    routePointIdentifier : 'Default',

    // CoBrowse related.
    coBrowseEnabled : true,
    coBrowseReady : false,
    coBrowseIframe : null,
    coBrowseKey : '',
    isPagePushKey : false,
    coBrowseOceanaPath : 'cobrowse-oceana/js/',
    coBrowseSDKPath : 'cobrowse-oceana/js/libs/',
    hideElements : [ 'chatPanelHidden', 'configPanel' ],

    /**
     * Add a new custom field. Must be unique
     * @param {String} title - must be at most 50 characters, and must not be blank.
     * @param {String} value - must be at most 255 characters.
     */
    addCustomFields: function(title, value) {
        'use strict';

        if (avayaGlobal.isStringEmpty(title)) {
            console.error("WebChat: Cannot have a custom field with an empty title!");
            return;
        }

        if (value && value.length > 255) {
            console.error("WebChat: Custom fields must not exceed 255 characters in length!");
            return;
        }

        // required because IE doesn't support the Array.prototype.find() method
        var contained = false;
        for (var i = 0; i < webChat.customFields.length; i++) {
            var field = webChat.customFields[i];
            if (field.title === title) {
                contained = true;
                break;
            }
        }
        if (!contained) {
            webChat.customFields.push({"title" : title, "value" : value});
        }
    },

    clearCustomFields: function() {
        'use strict';
        webChat.customFields = [];
    },

    removeCustomField: function(title){
        'use strict';
        var index = -1;
        for (var i = 0; i < webChat.customFields.length; i++) {
            var field = webChat.customFields[i];
            console.log(title, field);
            if (field.title === title) {
                index = i;
                break;
            }
        }
        if (index > -1) {
            webChat.customFields.splice(index, 1);
        }
    },

    /**
     * Append a specified link to the chat transcript.
     *
     * @param url
     * @param urlDestination
     * @param openAutomatically -
     *                false for Web-On-Hold URLs, but URLs from an agent may be
     *                changed
     * @param parentElement -
     *                the parent element
     */
    appendLink : function(url, urlDestination, openAutomatically, parentElement) {
        'use strict';
        var link = document.createElement('a');
        link.href = url;
        link.text = url;
        link.target = ('_' + urlDestination);
        parentElement.appendChild(link);

        if (openAutomatically) {
            window.open(link);
        }
    },

    /**
     * Log the user into the chat.
     */
    chatLogin : function() {

        'use strict';
        var wantsEmail = chatUI.getRequestTranscript();
        var topic = chatUI.getTopic();
        var leaseTime = chatConfig.leaseTime;
        console.debug('WebChat: Chat attributes are ' + JSON.stringify(chatLogon.attributes));

        // if the user didn't specify a valid email address, they can't
        // receive a transcript.
        if (avayaGlobal.isStringEmpty(webChat.custDetails.email)) {
            wantsEmail = false;
        }
        var calledParty = window.location.href;
        var msg;
        var custDetails = webChat.custDetails;

        if (!chatConfig.previouslyConnected) {
			if (webChat.customFields.length > 10){
				var customFieldsMessage = 'The limit of custom fields is 10. These fields were not added:';
				for (var i = 10; i < webChat.customFields.length; i++) {
					customFieldsMessage = customFieldsMessage.concat('\n', webChat.customFields[i].title);
				}
				webChat.customFields.splice(10, webChat.customFields.length-10);
				webChat.writeResponse(customFieldsMessage, chatConfig.writeResponseClassSystem);
            }

            msg = {
                'apiVersion' : '1.0',
                'type' : 'request',
                'body' : {
                    'method' : 'requestChat',
                    'deviceType' : navigator.userAgent,
                    'routePointIdentifier' : webChat.routePointIdentifier,
                    'workFlowType' : webChat.workflowType,
                    'requestTranscript' : wantsEmail,
                    'workRequestId' : avayaGlobal.getSessionStorage('contextId'),
                    'calledParty' : calledParty,
                    'leaseTime' : leaseTime,
                    'intrinsics' : {
                        'channelAttribute' : 'Chat',
                        'attributes' : chatLogon.attributes,
                        'email' : custDetails.email,
                        'name' : custDetails.firstName,
                        'lastName' : null,
                        // 'country' : custDetails.phone.country,
                        // 'area' : custDetails.phone.area,
                        'phoneNumber' : custDetails.phone.number,
                        'topic' : topic,
                        'message': custDetails.message,
                        'customFields' : webChat.customFields
                    },
                    'priority' : ewt.getPriority(),
                    'customData': webChat.customData
                }
            };
            webChat.writeResponse(chatConfig.openingChatText, chatConfig.writeResponseClassSystem);

        } else {
            msg = {
                'apiVersion' : '1.0',
                'type' : 'request',
                'body' : {
                    'method' : 'renewChat',
                    'guid' : webChat.guid,
                    'authenticationKey' : webChat.ak,
                }
            };
        }

        chatSocket.sendMessage(msg);
    },

    /**
     * Clears all timeouts on the page.
     */
    clearAllTimeouts : function() {
        'use strict';
        for (var i = 0; i < webChat.timeouts.length; i++) {
            clearTimeout(webChat.timeouts[i]);
        }
    },

    /**
     * Clears the customer's typing timeout.
     *
     * @param obj
     */
    clearTypingTimeout : function(obj) {
        'use strict';
        if (obj) {
            clearTimeout(obj);
        }
    },

    /**
     * Handles notification messages.
     *
     * @param message
     */
    handleNotification : function(message) { // NOSONAR: too complex
        // for Sonar, but cannot be reduced further
        'use strict';
        var body = message.body, method = body.method;
        if (method === chatConfig.jsonMethodRequestChat) {
            webChat.notifyRequestChat(body);
        } else if (method === chatConfig.jsonMethodRouteCancel) {
            webChat.notifyRouteCancel();
        } else if (method === chatConfig.jsonMethodRequestNewParticipant) {
            webChat.notifyNewParticipant(body);
        } else if (method === chatConfig.jsonMethodRequestIsTyping) {
            webChat.notifyIsTyping(body);
        } else if (method === chatConfig.jsonMethodRequestNewMessage) {
            webChat.notifyNewMessage(body);
        } else if (method === chatConfig.jsonMethodRequestCloseConversation) {
            webChat.notifyCloseConversation();
        } else if (method === chatConfig.jsonMethodRequestParticipantLeave) {
            webChat.notifyParticipantLeave(body);
        } else if (method === chatConfig.jsonMethodRequestNewPushMessage) {
            webChat.notifyNewPagePushMessage(body);
        } else if (method === chatConfig.jsonMethodRequestNewCoBrowseSessionKeyMessage) {
            webChat.notifyNewCoBrowseSessionKeyMessage(body);
        } else if (method === chatConfig.jsonMethodPing) {
            // do nothing with pings. They just confirm that the
            // WebSocket is open.
        } else if (method === chatConfig.jsonMethodFileTransfer) {
            webChat.notifyFileTransfer(body);
        } else {
            throw new TypeError('Received notification with unknown method: '.concat(method));
        }
    },

    /**
     * Initialise the chat.
     * @param {Boolean} disableControls - defines whether or not to disable the controls. If true, the user will not be able to type a message
     */
    initChat : function(disableControls, custDetails) {

        console.log("initChat");
        'use strict';

        // if the chat has already opened, don't bother opening it
        if (webChat.initCalled) {
            return;
        }

        if (disableControls === undefined) {
            disableControls = true;
        }

        webChat.custDetails = custDetails;
        chatUI.disableControls(disableControls);
        chatSocket.openSocket();
        webChat.initCalled = true;
    },

    /**
     * Notify the user that the chat is closed.
     *
     * @param body
     */
    notifyCloseConversation : function() {
        'use strict';
        // Server will close the websocket
        chatSocket.manualClose = false;
        chatConfig.dontRetryConnection = true;
        avayaGlobal.clearSessionStorage();
    },

    /**
     * Reset the chat.
     */
    resetChat : function() {
        'use strict';
        console.info('WebChat: Resetting chat');
        chatUI.resetChatUI();
        webChat.updateUsers();
        webChat.clearAllTimeouts();
        webChat.ak = null;
        webChat.guid = null;
        webChat.lastIsTypingSent = 0;
        chatSocket.resetWebSocket();
        chatConfig.dontRetryConnection = false;
    },

    /**
     * Notify the user that an agent's typing status has changed.
     *
     * @param body
     */
    notifyIsTyping : function(body) {
        'use strict';
        var isAgentTyping = body.isTyping;

        if (isAgentTyping === true) {
            var agent = webChat.users[body.agentId];
            agent.isTyping = isAgentTyping;
            webChat.updateTypingCell(agent, true);

            var agentTypeOut;
            if (agent.type === 'active_participant') {
                agentTypeOut = chatConfig.activeAgentTypeOut;
            } else if (agent.type === 'passive_participant') {
                agentTypeOut = chatConfig.passiveAgentTypeOut;
            } else {
                agentTypeOut = chatConfig.supervisorTypeOut;
            }

            if (agentTypeOut !== undefined) {
                webChat.clearTypingTimer(agentTypeOut);
            }

            agentTypeOut = setTimeout(function() {
                if (webChat.users !== undefined) {
                    agent.isTyping = false;
                    webChat.updateTypingCell(agent, false);
                }
            }, chatConfig.agentTypingTimeout);
            webChat.timeouts.push(agentTypeOut);
        }
    },

    /**
     * Notify the user of a new chat message.
     *
     * @param body
     */
    notifyNewMessage : function(body) {
        'use strict';
        var date = new Date(body.timestamp);
        var dateMessage = body.displayName + ' (' + date.toLocaleTimeString() + ')';
        webChat.writeResponse(dateMessage, chatConfig.writeResponseClassAgentDate);

        // get the chat message class. If it's a chatbot, use the chatbot class instead of the agent class.
        var chatMessageClass = chatConfig.writeResponseClassResponse;
        if (body.displayName === webChat.chatBotName) {
            chatMessageClass = chatConfig.writeResponseClassChatbot;
        }
       if (body.type === "widget") {
            webChat.writeResponse(body.data.text, chatMessageClass);
            webChat.createWidgets(body.data);
        }
        else {
            webChat.writeResponse(body.message, chatMessageClass);
        }
    },

    /**
     * Notify the user of a new page push message
     *
     * @param body
     */
    notifyNewPagePushMessage : function(body) {
        'use strict';
        var sentDate = new Date(body.timestamp), url = body.pagePushURL, name = body.displayName;

        // Check for Co-Browse enabled.
        var sessionKey = avayaGlobal.getParameterByName('key', body.pagePushURL);
        var dateMessage;

        if (sessionKey !== null) {
            console.debug('WebChat/CoBrowse: Recieved Co-Browse page push request for session ' + sessionKey +
                    '.');
            webChat.coBrowseKey = sessionKey;
            webChat.isPagePushKey = true;
            coBrowseUI.showCoBrowseIframe(url);

            // show the Co-Browsing notification
            dateMessage = 'System: (' + sentDate.toLocaleTimeString() + ')';
            webChat.writeResponse(dateMessage, chatConfig.writeResponseClassSystem);
            webChat.writeResponse(chatConfig.coBrowseSessionStartedText, chatConfig.writeResponseClassSystem);
        } else {
            // show the Page-Push notification
            dateMessage = name + ' (' + sentDate.toLocaleTimeString() + ')';
            var systemMessage = chatConfig.pagePushMessageText.concat(url);
            webChat.writeResponse(dateMessage, chatConfig.writeResponseClassAgentDate);
            webChat.writeResponse(systemMessage, chatConfig.writeResponseClassSystem);
        }
    },

    postCoBrowseDialogCleanUp : function() {
        'use strict';

        var contentWindow = webChat.coBrowseIframe.contentWindow;
        var iFramedCoBrowse = contentWindow.coBrowse;
        var iFramedCoBrowseUI = contentWindow.coBrowseUI;

        if (iFramedCoBrowse && iFramedCoBrowseUI) {

            iFramedCoBrowseUI.closeHangingDialogs();

        } else {
            coBrowseUI.closeHangingDialogs();
        }

    },

    /**
     * Notify the user of a new co-browse session key message
     *
     * @param body
     */
    notifyNewCoBrowseSessionKeyMessage : function(body) {
        'use strict';
        webChat.postCoBrowseDialogCleanUp();
        // TODO: check format of the key.
        var coBrowseKey = body.sessionKey;

        // makes sure that i-frame is closed before key-push load
        webChat.cleanUpPagePushCoBrowse();

	// show the Co-Browsing notification
        var dateMessage = 'System: (' + new Date().toLocaleTimeString() + ')';
        webChat.writeResponse(dateMessage, chatConfig.writeResponseClassSystem);
	    webChat.writeResponse(chatConfig.coBrowseSessionStartedText, chatConfig.writeResponseClassSystem);
        webChat.joinKeyPushCoBrowse(coBrowseKey);
    },

    /**
     * Notify the user that an agent has joined the chat.
     *
     * @param body
     */
    notifyNewParticipant : function(body) {
        'use strict';

        // enable the controls now in case there are any missing fields in the request
        chatUI.disableControls(false);
        webChat.stopOnHoldMessages();

        var id = body.agentId;
        var role = body.role;
        var firstMessage = localStorage.getItem("userMessage");

        // assign chatbot name here. The UI will then use whatever name was assigned in AutomationController.
        if (id === 'AvayaAutomatedResource') {
            webChat.chatBotName = body.displayName;
        }

        if (webChat.checkAgentVisibility(id, role)) {
            webChat.writeResponse(chatConfig.agentJoinedMessage, chatConfig.writeResponseClassSystem);
            webChat.writeResponse(chatConfig.curentTimeIntervalText, chatConfig.writeResponseClassCurentTimeInterval);

            setTimeout(function () {
                if (firstMessage !== null) {
                    webChat.sendChatMessage(firstMessage);
                    localStorage.removeItem("userMessage");
                }
            }, 3000);

        }

        var agents = body.participants;
        webChat.updateUsers(agents);
	if (body.webOnHoldComfortGroup !== undefined && body.webOnHoldComfortGroup.length > 0) {
            webChat.webOnHoldComfortGroups = body.webOnHoldComfortGroup[0];
        }
    },

    checkAgentVisibility : function(id, role) {
        'use strict';

        // check if notifications are allowed/required
        var announceBot = (id === 'AvayaAutomatedResource' && !chatConfig.suppressChatbotPresence);
        var announceObserve = (role === 'supervisor_observe' && chatConfig.notifyOfObserve);
        var announceBarge = (role === 'supervisor_barge' && chatConfig.notifyOfBarge && !chatConfig.notifyOfObserve);

        // if notifications are allowed/required, display them
        if (announceBot || announceObserve || announceBarge || role === 'active_participant') {
            return true;
        }
        return false;
    },

    /**
     * Notify the user that an agent has left the chat.
     *
     * @param body
     */
    notifyParticipantLeave : function(body) {
        'use strict';

        var id = body.agentId;
        var agents = body.participants;

        // workaround to make sure Chrome closes on a two-node cluster
        if (body.endChatFlag) {
            chatConfig.dontRetryConnection = true;
        }

        // check if this user is actually present in the users to avoid multiple displays of "An agent has left".
        // This does not affect the chatbot.
        var isAgentContained = false;
        for (var i = 0; i < agents.length; i++) {
            if (agents[i].id === id) {
                isAgentContained = true;
                break;
            }
        }

        var leaveReason = body.leaveReason.toLowerCase();
        console.log(leaveReason);

        // check if this is the chatbot, and if it is to be suppressed.
        var suppressBot = (leaveReason === 'escalate' && chatConfig.suppressChatbotPresence);

        // if there are no users, check if this is a transfer.
        if (Object.keys(body.participants).length === 0) {
            console.info('WebChat: Only the customer remains in the room.');
            chatUI.disableControls(true);
            webChat.startOnHoldMessages();

            if (leaveReason === 'transfer') {
                webChat.writeResponse(chatConfig.transferNotificationText, chatConfig.writeResponseClassSystem);
            } else if (leaveReason === 'requeue') {
                webChat.writeResponse(chatConfig.requeueNotificationText, chatConfig.writeResponseClassSystem);
			} else if (leaveReason === 'escalate' && !chatConfig.suppressChatbotPresence) {
                webChat.writeResponse(chatConfig.chatbotTransferNotification, chatConfig.writeResponseClassSystem);
            } else {
                webChat.writeResponse(chatConfig.agentLeftMessage, chatConfig.writeResponseClassSystem);
            }

        } else {
            // Let the user know that an agent has left, unless it's the chatbot and chatbot notifications are suppressed.
            if (!suppressBot && !isAgentContained) {
                webChat.writeResponse(chatConfig.agentLeftMessage, chatConfig.writeResponseClassSystem);
            }
        }

        webChat.updateUsers(agents);
    },

    /**
     * Notifies the user that they have entered the chat.
     *
     * @param body
     */
    notifyRequestChat : function(body) {
        'use strict';

        webChat.guid = body.guid;
        webChat.custDetails.displayName = body.intrinsics.name;
        webChat.ak = body.authenticationKey;
        var firstMessage = localStorage.getItem("userMessage");
        var wrid = body.workRequestId;
        // console.info("WebChat: workRequestId is " + wrid + ", contactUUID/chatroom key is " + webChat.ak + ". Valid email? " + body.wasEmailValid);

        if (chatSocket.retries > 0) {
            chatSocket.resetConnectionAttempts();
        }

        // Send up the Co-Browse status of this page.
        var coBrowseEnabled = typeof coBrowse !== 'undefined' && webChat.coBrowseEnabled;
        webChat.sendCoBrowseStatus(coBrowseEnabled);

        // if the customer has already been connected, don't play the on
        // hold messages
        if (!chatConfig.previouslyConnected) {
            webChat.writeResponse(chatConfig.chatOpenedText, chatConfig.writeResponseClassSystem);
            chatConfig.previouslyConnected = true;

            if (!body.isEmailValid) {
                webChat.writeResponse(chatConfig.invalidEmailAddressText, chatConfig.writeResponseClassSystem);
                webChat.custDetails.email = '';
            }

            webChat.webOnHoldComfortGroups = body.webOnHoldComfortGroups[0];
            webChat.webOnHoldURLs = body.webOnHoldURLs[0];
            webChat.startOnHoldMessages();
        } else {
            webChat.writeResponse(chatConfig.successfulReconnectionText, chatConfig.writeResponseClassSystem);
        }
    },

    /**
     * Notifies the user that no agent could be found to route the chat to.
     */
    notifyRouteCancel : function() {
        'use strict';
        webChat.writeResponse(chatConfig.routeCancelText, chatConfig.writeResponseClassSystem);
        chatConfig.dontRetryConnection = true;
    },

    /**
     * Notifies the user of a new file transfer.
     */
    notifyFileTransfer : function(body) {
        'use strict';
        console.info('WebChat: Notifying of file transfer');
        var agentname = body.agentName;
        var uuid = body.uuid;
        var wrid = body.workRequestId;
        var url = links.getFileDownloadUrl(uuid, wrid);
        var filename = body.name;
        var timestamp = new Date().toLocaleString();
	    var dateMessage = agentname + ' (' + timestamp + ')';
	    webChat.writeResponse(dateMessage, chatConfig.writeResponseClassAgentDate);

        var message = chatConfig.fileTransferMessageText;
        message = message.replace('{0}' , agentname);
        message = message.replace('{1}' , filename);
        message = message.replace('{2}' , timestamp);
        message = message.replace('{3}' , url);
        webChat.writeResponse(message, chatConfig.writeResponseClassResponse);
    },

    /**
     * Notifies the user that a video or voice chat is being opened.
     *
     * @param body
     */
    notifyVideoOrVoice : function(body) {
        'use strict';
        console.debug('WebChat: ' + body);
    },

    /**
     * Called when the customer starts typing.
     * The current behavious is that pressing Enter (keycode 13) will send the chat message.
     * @param event
     */
    onType : function(event) {
        'use strict';
        if (event.keyCode === 13 && event.ctrlKey && $("#outmessage").val().trim().length > 0) {
            webChat.sendChatMessage();
        } else {
            webChat.startTypingTimer();
        }
    },

    /**
     * Play the Web-On-Hold messages inside a specific array.
     *
     * @param array
     *                of Web-On-Hold comfort messages or URLs.
     */
    playOnHoldMessage : function(array) {
        'use strict';
        var currentMsg;

        // if this has a urls array, it's a WebOnHold URL
        // otherwise, it's a comfort message
        if (array.urls !== undefined) {
            currentMsg = array.urls[array.currentSequence];
            var msgText = array.description + ': ' + currentMsg.url;
            webChat.writeResponse(msgText, chatConfig.writeResponseClassSystem);

        } else {
            currentMsg = array.messages[array.currentSequence];
            webChat.writeResponse(currentMsg.message, chatConfig.writeResponseClassSystem);
        }

        array.currentSequence++;
        if ((array.numberOfMessages !== undefined && array.currentSequence >= array.numberOfMessages) ||
                (array.urls !== undefined && array.currentSequence >= array.urls.length)) {
            array.currentSequence = 0;
        }

    },

    /**
     * Sends a close chat request to the server.
     */
    quitChat : function() {
        'use strict';
        // Prevent reconnect attempts if customer clicks 'Close' while chat is
        // reconnecting
        chatConfig.dontRetryConnection = true;
        chatSocket.manualClose = true;


        webChat.clearAllTimeouts();
        if (webSocket !== null && webSocket.readyState === webSocket.OPEN) {
            var closeRequest = {
                'apiVersion' : '1.0',
                'type' : 'request',
                'body' : {
                    'method' : 'closeConversation'
                }
            };
            webChat.writeResponse(chatConfig.closeRequestText, chatConfig.writeResponseClassSystem);
            chatSocket.sendMessage(closeRequest);
        }
    },

    /**
     * Sends a chat message to the server. If the message box is empty, nothing
     * is sent.
     */
    sendChatMessage : function(item) {
        'use strict';
        if (!!item) {
            var text = item;
        } else {
            var text = chatUI.getChatMessage();
        }

        if (!avayaGlobal.isStringEmpty(text)) {

            // add the timestamp message, then the chat.
            var timestamp = new Date().toLocaleTimeString();
            var dateMessage = webChat.custDetails.displayName + ' (' + timestamp + ')';
            webChat.writeResponse(dateMessage, chatConfig.writeResponseClassDate);
            webChat.writeResponse(text, chatConfig.writeResponseClassSent);

            var message = {
                'apiVersion' : '1.0',
                'type' : 'request',
                'body' : {
                    'method' : 'newMessage',
                    'message' : text,
                    'type': 'text',
                    'data': {
                        'message': text
                    },
                    'customData': webChat.customData
                }
            };
            chatSocket.sendMessage(message);
            chatUI.clearMessageInput();
        }
    },

    /**
     * Sends a widget response to the server. If the message box is empty, nothing
     * is sent.
     */
    sendWidgetMessage: function () {
        'use strict';

        var timestamp = new Date().toLocaleTimeString();
        var custName = webChat.custDetails.displayName;
        var dateMessage = custName + ' (' + timestamp + ')';
        webChat.writeResponse(dateMessage, chatConfig.writeResponseClassDate);
        webChat.writeResponse(this.getAttribute('value'), chatConfig.writeResponseClassSent);

        var message = {
            'apiVersion': '1.0',
            'type': 'request',
            'body': {
                'method': 'newMessage',
                'message': this.getAttribute('value'),
                'type': 'customResponse',
                'data': {
                    "message": this.getAttribute('value'),
                    "correlationId": this.getAttribute('data-correlationId')
                },
                'customData': webChat.customData
            }
        };

        chatSocket.sendMessage(message);

    },

    /**
     * Lets the agents know that the customer is typing.
     *
     * @param isUserTyping
     */
    sendIsTyping : function(isUserTyping) {
        'use strict';
        var isTypingMessage = {
            'apiVersion' : '1.0',
            'type' : 'request',
            'body' : {
                'method' : 'isTyping',
                'isTyping' : isUserTyping
            }
        };

        // update lastisTypingSent timestamp
        webChat.lastIsTypingSent = Date.now();

        chatSocket.sendMessage(isTypingMessage);
    },

    /**
     * Lets the agent know if the customer's client is enabled for Co-Browse.
     *
     * @param isEnabled
     */
    sendCoBrowseStatus : function(isEnabled) {
        'use strict';
        var coBrowseStatusMessage = {
            'apiVersion' : '1.0',
            'type' : 'request',
            'body' : {
                'method' : 'coBrowseStatus',
                'isEnabled' : isEnabled
            }
        };

        chatSocket.sendMessage(coBrowseStatusMessage);
    },

    /**
     * Ping the WebSocket to see if it is still open on both ends. JavaScript
     * doesn't have an API for this, so this is a workaround.
     */
    sendPing : function() {
        'use strict';
        var ping = {
            'apiVersion' : '1.0',
            'type' : 'request',
            'body' : {
                'method' : 'ping'
            }
        };
        chatSocket.sendMessage(ping);
    },

    /**
     * Start playing the on hold messages, if either the messages or the URLs
     * are defined. The intervals are defined in the OCP Admin.
     */
    startOnHoldMessages : function() {
        'use strict';
        // console.info('WebChat: Starting the On Hold messages');
        var onHoldUrlsDefined = (webChat.webOnHoldURLs.urls.length > 0);
        var onHoldMessagesDefined = (webChat.webOnHoldComfortGroups.messages.length > 0);

        if (!onHoldUrlsDefined && !onHoldMessagesDefined) {
            console.warn('WebChat: On Hold messages are not defined!');
        }

        if (onHoldMessagesDefined) {
            // sort the WebOnHoldComfortGroups according to sequence
            var newMessages = webChat.webOnHoldComfortGroups.messages.sort(function(a, b) {
                return (a.sequence - b.sequence);
            });
            webChat.webOnHoldComfortGroups.messages = newMessages;
            webChat.webOnHoldComfortGroups.currentSequence = 0;

            webChat.onHoldComfortInterval = setInterval(function() {
                webChat.playOnHoldMessage(webChat.webOnHoldComfortGroups);
            }, webChat.webOnHoldComfortGroups.delay * 1000);
            webChat.timeouts.push(webChat.onHoldComfortInterval);
        }

        if (onHoldUrlsDefined) {
            webChat.webOnHoldURLs.currentSequence = 0;

            webChat.onHoldUrlInterval = setInterval(function() {
                webChat.playOnHoldMessage(webChat.webOnHoldURLs);
            }, webChat.webOnHoldURLs.holdTime * 1000);
            webChat.timeouts.push(webChat.onHoldUrlInterval);
        }

    },

    stopOnHoldMessages : function() {
        'use strict';
        console.info('Web On Hold: Stopping messages');
        clearInterval(webChat.onHoldUrlInterval);
        clearInterval(webChat.onHoldComfortInterval);
    },

    /**
     * Start the customer's typing timer.
     */
    startTypingTimer : function() {
        'use strict';
        var isTypingTimer = Date.now();
        var timerExpiryTime = webChat.lastIsTypingSent + webChat.timeBetweenMsgs;

        if (isTypingTimer >= timerExpiryTime) {
            webChat.sendIsTyping(true);
        }

    },

    /**
     * Update the typing image for a specific agent.
     *
     * @param agent
     * @param isTyping
     */
    updateTypingCell : function(agent, isTyping) {
        'use strict';
        var imageSrc;
        if (agent.agentType === 'active_participant' || agent.agentType === 'passive_participant') {
            imageSrc = isTyping === true ? chatConfig.agentTypingImage : chatConfig.agentImage;
        } else {
            imageSrc = isTyping === true ? chatConfig.supervisorTypingImage : chatConfig.supervisorImage;
        }

        // find which index this agent is. If the index is -1 (likely due to timing issues with a single agent),
        // default to 0
        var index = Object.keys(webChat.users).indexOf(agent);
        if (index < 0) {
            index = 0;
        }
        // chatUI.updateUserImage(index, imageSrc, "", isTyping === true ? agent.displayName.concat(' is typing') : agent.displayName);
    },

    /**
     * Update the users section when an agent leaves or joins.
     *
     * @param agents
     */
    updateUsers : function(agents) {
        'use strict';
        webChat.users = {};
        chatUI.clearUsers();

        if (agents !== undefined) {
            for (var i = 0; i < agents.length; i++) {
                var agent = agents[i];
                if (webChat.checkAgentVisibility(agent.id, agent.type) || agent.type === 'passive_participant') {
                    console.debug('WebChat: Adding agent with id ' + agent.id + ' and name ' + agent.name);

                    var src = (agent.type.substring(0, 10) === 'supervisor') ? chatConfig.supervisorImage
                            : chatConfig.agentImage;
                    var className = '';
                    // chatUI.updateUserImage(i, src, className,agent.name);

                    webChat.users[agent.id] = {
                        displayName : agent.name,
                        isTyping : false,
                        agentType : agent.type
                    };
                }

            }
        }
    },

    /**
     * split text string on white-space and add white-space
     * after each entry to maintain message structure
     *
     *
     * @param text
     */
    splitText : function(text) {
        'use strict';
        var textArray = text.split(" ");
        var output = [];
        for (var i = 0; i < textArray.length; i++) {
            output.push(textArray[i]);
            output.push(" ");
        }

        return output;

    },

    /**
     * Writes the specified text out to the transcript box and includes an
     * autoscroll mechanism.
     *
     * @param text
     * @param msgClass
     */
    writeResponse : function(textAll, msgClass) {
        'use strict';
        //hid message time
        var text = textAll.replace(/\d{2}:\d{2}/,'');

        var paragraph = document.createElement('div');
        paragraph.className = msgClass;
        var wrapMsg = document.createElement('div');
        wrapMsg.className = "msgWrap";
        var wrapDateMsg = document.createElement('div');
        wrapDateMsg.className = "msgDateWrap";
        // get message split by white-space
        var textArray = webChat.splitText(text);

        // append each element of the message as a string or a link
        for (var i = 0; i < textArray.length; i++) {

            var span = document.createElement('span');
            // check that URLs have at least one character in the path else display as text
            // have to use "indexOf === 0", as IE does not support the startsWith method

            if ((textArray[i].indexOf("http://") === 0 && textArray[i].length > 7) || (textArray[i].indexOf("https://") === 0 && textArray[i].length > 8)) {
                if ((textArray[i].search(/cell.motivtelecom/i) !== -1) ||
                (textArray[i].search(/shop.motivtelecom/i) !== -1) ||
                (textArray[i].search(/chat.motivtelecom/i) !== -1)) {
                    webChat.appendLink(textArray[i], 'blank', false, span);
                } else if ((textArray[i].search(/motivtelecom/i) !== -1) || (textArray[i].search(/motiv/i) !== -1)) {
                    webChat.appendLink(textArray[i], 'self', false, span);
                } else {
                    webChat.appendLink(textArray[i], 'blank', false, span);
                }
            }
            //if http(s) appears without a qualifing '://', it will be displayed as normal text
            else {
                span.textContent = textArray[i];
            }
            wrapMsg.appendChild(span);
        }

        var span = document.createElement('span');
        span.textContent = webChat.getActualHour();
        wrapDateMsg.appendChild(span);
        paragraph.appendChild(wrapMsg);
        wrapMsg.appendChild(wrapDateMsg);

        chatUI.appendParagraph(paragraph);
        if ( msgClass === 'response' ) {
            webChat.unreadMessageCounter();
        }
    },

    unreadMessageCounter: function () {
        var styleBlock = $('#liveChatLinkOpen--notice').css('display');
        var styleBlockChatPanelHidden = $('#chatPanelHidden').css('display');
        var elemWrap = $('#liveChatLink').attr('status');
        var elemCount = $('#liveChatLinkOpen--notice').attr('count');

        if ( elemWrap === "close" && styleBlockChatPanelHidden !== "block") {
            var elemCount = $('#liveChatLinkOpen--notice').attr('count');
            if ( styleBlock === "none" ) {
                $('#liveChatLinkOpen--notice').css('display', 'block');
            }
            $('#liveChatLinkOpen--notice').attr("count", +elemCount + 1);
        }
    },

    addZero: function (i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    },

    getActualHour: function () {
        var d = new Date();
        var h = webChat.addZero(d.getHours());
        var m = webChat.addZero(d.getMinutes());
        return h + ":" + m;
    },

    getActualDay: function () {
        var d = new Date();
        var month = d.getMonth()+1;
        var day = d.getDate();
        var output = d.getFullYear() + '.' +
            (month<10 ? '0' : '') + month + '.' +
            (day<10 ? '0' : '') + day;
        return output;
    },

    /* This function is used to plot widgets */
    createWidgets: function (data) {
	'use strict';
        var type = data.widgetType.split('|');

        if (type[0].toLowerCase() === (widgetConfig.selector).toLowerCase()) {
            webChat.createSelectorElement(data, type[1]);
        }
        else if (type[0].toLowerCase() === (widgetConfig.click).toLowerCase()) {
            webChat.createClickElement(data, type[1]);
        }
        else {
            console.warn("This type of widget (" + type + ") is not supported");
        }

    },

    createSelectorElement: function (data, type) {
        'use strict';
        var divElement = document.createElement('div');
        divElement.className = chatConfig.writeResponseClassResponse;

        if (type.toLowerCase() === (widgetConfig.radio).toLowerCase()) {
            webChat.createRadioElement(data, divElement);
        }

        divElement.append(document.createElement("br"));
        chatUI.appendParagraph(divElement);
    },

    createClickElement: function (data, type) {
        'use strict';
        var divElement = document.createElement('div');
        divElement.className = chatConfig.writeResponseClassResponse;

        if (type.toLowerCase() === (widgetConfig.button).toLowerCase()) {
            webChat.createButtonElement(data, divElement);
        }

        divElement.append(document.createElement("br"));
        chatUI.appendParagraph(divElement);

    },

    createRadioElement: function (data, divElement) {
        'use strict';
        for (var item in data.selectItems ) {
            var radioInput = document.createElement("label");
            var radioElement = document.createElement("input");
            radioElement.type = "radio";
            radioElement.value = data.selectItems[item].product;
            radioElement.id =data.selectItems[item].id;
            radioElement.name = data.selectorType;
            radioElement.setAttribute("data-correlationId", data.correlationId);
            radioElement.addEventListener('click', webChat.sendWidgetMessage);
            radioInput.append(radioElement);
            divElement.append(radioInput);
            var spanElement = document.createElement('span');
            var textElement = document.createTextNode(data.selectItems[item].product);
            spanElement.append(textElement);
            spanElement.setAttribute("class", "radio-widget");

            divElement.append(spanElement);
            divElement.append(document.createElement("br"));
        }

    },

    createButtonElement: function (data, divElement) {
        'use strict';
	for (var item in data.selectItems )  {
            var btnElement = document.createElement("BUTTON");
            btnElement.setAttribute("class", "button-widget");
            btnElement.setAttribute("id",data.selectItems[item].id);
            btnElement.setAttribute("value", data.selectItems[item].product);
            btnElement.setAttribute("data-correlationId", data.correlationId);
            btnElement.addEventListener('click', webChat.sendWidgetMessage);
            btnElement.append(document.createTextNode(data.selectItems[item].product));
            divElement.append(btnElement);
        }
    },

    /**
     * Utility function to set the workflow type for routing.
     *
     * @param newWorkflowType
     */
    setWorkflowType : function(newWorkflowType) {
        'use strict';
        webChat.workflowType = newWorkflowType;
    },

    /**
     * Gather the required chat elements
     */
    gatherChatElements : function() {
        'use strict';
        var find = avayaGlobal.getEl;
        webChat.coBrowseIframe = find('cobrowse');
    },

    ////////////////////////////////////////////////////////////////////////////
    // Subscribed Co-Browse iFrame Events
    ////////////////////////////////////////////////////////////////////////////

    onLoadCoBrowseIframe : function() {
        'use strict';

        var contentWindow = webChat.coBrowseIframe.contentWindow;
        var iFramedCoBrowse = contentWindow.coBrowse;
        var iFramedCoBrowseUI = contentWindow.coBrowseUI;

        if (iFramedCoBrowse !== undefined && iFramedCoBrowseUI !== undefined) {
            iFramedCoBrowseUI.addListener(webChat);
            iFramedCoBrowse.init(webChat.coBrowseSDKPath, console, [ webChat,
                    iFramedCoBrowseUI ], links.coBrowseHost);

            contentWindow.onbeforeunload = webChat.onBeforeUnloadCoBrowseIframe;
        }
    },

    onBeforeUnloadCoBrowseIframe : function() {
        'use strict';

        var contentWindow = webChat.coBrowseIframe.contentWindow;
        var iFramedCoBrowse = contentWindow.coBrowse;
        var iFramedCoBrowseUI = contentWindow.coBrowseUI;

        if (iFramedCoBrowse !== undefined && iFramedCoBrowseUI !== undefined) {
            iFramedCoBrowseUI.closeHangingDialogs();
        }
    },

    ////////////////////////////////////////////////////////////////////////////
    // Subscribed Co-Browse Events
    ////////////////////////////////////////////////////////////////////////////

    onCoBrowseReady : function(source) {
        'use strict';

        if (webChat.isPagePushKey) {
            // Came from the iFrame handle it differently.
            if (webChat.coBrowseKey) {
                webChat.joinPagePushCoBrowse();
            }
        } else {
            // Came from the Co-Browse on this page.
            webChat.coBrowseReady = true;
            coBrowseUI.showCoBrowseLinkDiv();
        }
    },

    onCoBrowseSessionClose : function(source, title, text) {
        'use strict';

        webChat.coBrowseKey = '';
        if (webChat.initCalled) {

            // we don't have a timestamp or display name for this,
            // so use the local time and claim it's done by the system.
            var date = new Date();
            webChat.writeResponse('System ('+ date.toLocaleTimeString() + ')', chatConfig.writeResponseClassSystem);
            webChat.writeResponse(chatConfig.coBrowseSessionFinishedText, chatConfig.writeResponseClassSystem);
        }
    },

    onCoBrowseStopSuccess : function(source) {
        'use strict';

        if (webChat.initCalled) {

            // we don't have a timestamp or display name for this,
            // so use the local time and claim it's done by the system.
            var date = new Date();
            webChat.writeResponse('System ('+ date.toLocaleTimeString() + ')', chatConfig.writeResponseClassSystem);
            webChat.writeResponse(chatConfig.coBrowseSessionFinishedText, chatConfig.writeResponseClassSystem);
        }
    },

    // Subscribed coBrowseUI events.
    onCoBrowseUIJoinRequest : function(source, name, coBrowseKey) {
        'use strict';

        var hiddenElements = webChat.hideElements.concat(coBrowseUI.hiddenElements);
        coBrowse.joinSession(name, coBrowseKey, hiddenElements);
        coBrowseUI.hideCoBrowseLinkDiv();
    },

    onCoBrowseUIJoinFailure : function(source) {
        'use strict';

        if (webChat.coBrowseKey) {
            if (webChat.isPagePushKey) {
                webChat.cleanUpPagePushCoBrowse();
            }
        }
    },

    onCoBrowseUICleanUp : function(source) {
        'use strict';

        if (webChat.isPagePushKey) {
            webChat.cleanUpPagePushCoBrowse();
        }

        coBrowseUI.showCoBrowseLinkDiv();
    },

    // CoBrowse related functions.
    initCoBrowse : function() {
        'use strict';

        coBrowse.init(webChat.coBrowseSDKPath, console, [ webChat, coBrowseUI ],
                links.coBrowseHost);
    },

    joinPagePushCoBrowse : function() {
        'use strict';

        // Hide join dialog for other CoBrowse on this page.
        coBrowseUI.closeDialog(coBrowseUI.proactiveJoinDialogId);
        coBrowseUI.hideCoBrowseLinkDiv();

        var iFramedCoBrowse = webChat.coBrowseIframe.contentWindow.coBrowse;
        if (iFramedCoBrowse) {
            var hiddenElements = webChat.hideElements.concat(coBrowseUI.hiddenElements);
            iFramedCoBrowse.joinSession(webChat.custDetails.displayName, webChat.coBrowseKey, hiddenElements);
        } else {
            // TODO: Handle error.
        }
    },

    joinKeyPushCoBrowse : function(coBrowseKey) {
        'use strict';

        var hiddenElements = webChat.hideElements.concat(coBrowseUI.hiddenElements);
        coBrowse.joinSession(webChat.custDetails.displayName, coBrowseKey, hiddenElements);
        coBrowseUI.hideCoBrowseLinkDiv();
    },

    cleanUpPagePushCoBrowse : function() {
        'use strict';

        webChat.isPagePushKey = false;
        coBrowseUI.hideCoBrowseIframe();
    },

    /**
     * Call this to set up webChat.
     */
    setupWebChat: function() {
        'use strict';
        // check if this browser supports required features
        avayaGlobal.detectBrowserSupport();
        links.setupSecurity();

        webChat.gatherChatElements();

        // set up the UI
        chatUI.setup();

        // Set Co-Browsing iFrame onLoad handler. Comment out if not using Co-Browsing.
        // webChat.coBrowseIframe.onload = webChat.onLoadCoBrowseIframe;

        // Setup Co-Browsing instance on this page. Comment out if not using Co-Browsing
        //coBrowseUI.addListener(webChat);
        //webChat.initCoBrowse();

        // check if there was a chat in progress before reloading
        chatSocket.reloadAfterRefresh();

        ewt.requestEwt();
        chatLogon.saveAttributeCount();

        setTimeout(() => {
            var reloadTimestamp = parseInt(avayaGlobal.getSessionStorage("reloadTimestamp"));
            if (!isNaN(reloadTimestamp)) {
                var currentTimestamp = Date.now();
                var expired = (currentTimestamp - reloadTimestamp) >= (chatConfig.refreshTimeoutSeconds * 1000);

                if (!expired && avayaGlobal.getSessionStorage("custDataOpened")) {

                    $('#chatForm').show();
                    $('#chatWelcomeForm').hide();
                    $('#chatPanel').dialog({
                        width: 404,
                        height: 'auto',
                        dialogClass: 'fixedPosition chatPosition communicationForm personalForm authFormChat',
                    }).on('dialogclose', function (event) {
                        avayaGlobal.removeFromSessionStorage("custDataOpened")
                    });
                    avayaGlobal.setSessionStorage("custDataOpened", 1)
                }
            }
        }, 500)

        window.onbeforeunload = function() {
            if (webChat.initCalled) {
                chatSocket.setupRefresh();
                //"You're about to end your session, are you sure?";
            } else {
                avayaGlobal.setSessionStorage("reloadTimestamp", Date.now());
            }
        };

		window.onunload = function() {
			if (coBrowse !== 'undefined') {
				coBrowse.stopSession();
				return "Ending session";
            }
        };

    },

    /**
     * Retrieve the current transcript from the UI's perspective. Used mainly for rebuilding after a refresh.
     * @returns {Element.children|.document@call;getElementById.children}
     */
    getCurrentTranscript: function() {
        'use strict';
        return chatUI.getTranscriptElements();
    }
};

document.onreadystatechange = function(){
    if(document.readyState === 'complete'){
        webChat.setupWebChat();
    }
};