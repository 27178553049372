import "jquery";
import "./js/avayaGlobal";
import "./js/libs/bootstrap.min.js";
import "./js/libs/jquery-ui/jquery-ui";
import "./js/estimatedWaitTime";
import "./js/links";
import "./js/webChat";
import "./js/webChatConfig";
import "./js/webChatLogon";
import "./js/webChatSocket";
import "./js/webChatUI";
