/**
 *  Copyright 2018 Avaya Inc. All Rights Reserved.
 *
 * Usage of this source is bound to the terms described in
 * licences/License.txt
 *
 * Avaya - Confidential & Proprietary. Use pursuant to your signed agreement or
 * Avaya Policy
 *
 * Defines configuraiton parameters for chat.
 */

// the WebSocket is globally accessible - there should only ever be one on the page?
var webSocket;


/**
 * Defines keywords for various widget types.
 */
var widgetConfig ={
    radio :"radio",
    button : "button",
    selector : "selector",
    click : "click"
};

var params = window.REDUX_DATA.chatFormFunc.chatFormItems.params ? window.REDUX_DATA.chatFormFunc.chatFormItems.params : false;
var showOpeningChatText = params ? params.form.showOpeningChatText : false;
var showReloadingPageText = params ? params.form.showReloadingPageText : false;
var showChatOpenedText = params ? params.form.showChatOpenedText : false;
var showInvalidEmailAddressText = params ? params.form.showInvalidEmailAddressText : false;
var showSuccessfulReconnectionText = params ? params.form.showSuccessfulReconnectionText : false;
var showCloseRequestText = params ? params.form.showCloseRequestText : false;
var showConnectionClosedText = params ? params.form.showConnectionClosedText : false;
var showConnectionErrorText = params ? params.form.showConnectionErrorText : false;
var showClosedForMaintenanceText = params ? params.form.showClosedForMaintenanceText : false;
var showAttemptingToReconnectText = params ? params.form.showAttemptingToReconnectText : false;
var showUnableToReconnectText = params ? params.form.showUnableToReconnectText : false;
var showPanelCloseText = params ? params.form.showPanelCloseText : false;
var showErrorOccurredText = params ? params.form.showErrorOccurredText : false;
var showAgentJoinedMessage = params ? params.form.showAgentJoinedMessage : false;
var showAgentLeftMessage = params ? params.form.showAgentLeftMessage : false;
var showRouteCancelText = params ? params.form.showRouteCancelText : false;
var showFileTransferMessageText = params ? params.form.showFileTransferMessageText : false;
var showTransferNotificationText = params ? params.form.showTransferNotificationText : false;
var showRequeueNotificationText = params ? params.form.showRequeueNotificationText : false;
var showChatbotTransferNotification = params ? params.form.showChatbotTransferNotification : false;
var showPagePushMessageText = params ? params.form.showPagePushMessageText : false;
var suppressChatbotPresence = params ? params.form.suppressChatbotPresence : false;
var showDefaultUsername = params ? params.form.showDefaultUsername : false;

window.chatConfig = {

    logonHandleUser: null,
    totalNumberOfRetries : 0,
    maxNumberOfRetries : 200,
    retryInterval : 3000,

    // If set to true, if the Websocket connection fails we will not attempt to re-establish the session
    dontRetryConnection : false,
    reconnectionTimeout : null,
    previouslyConnected : false,
    resetTimer : 5000,

    // message types
    messageTypeAck : 'acknowledgement',
    messageTypeError : 'error',
    messageTypeNotification : 'notification',

    // notification messages are further broken down
    jsonMethodRequestChat : 'requestChat',
    jsonMethodRouteCancel : 'routeCancel',
    jsonMethodRequestNewParticipant : 'newParticipant',
    jsonMethodRequestIsTyping : 'isTyping',
    jsonMethodRequestNewMessage : 'newMessage',
    jsonMethodRequestCloseConversation : 'closeConversation',
    jsonMethodRequestParticipantLeave : 'participantLeave',
    jsonMethodRequestNewPushMessage : 'newPushPageMessage',
    jsonMethodRequestNewCoBrowseSessionKeyMessage : 'newCoBrowseSessionKeyMessage',
    jsonMethodPing : 'pong',
    jsonMethodFileTransfer : 'newAgentFileTransfer',

    openingChatText: showOpeningChatText ? params.form.openingChatText ? params.form.openingChatText : "Sending Login Detailzzzz" : '',
    reloadingPageText: showReloadingPageText ? params.form.reloadingPageText ? params.form.reloadingPageText : "Opening chat after page refresh" : '',
    chatOpenedText: showChatOpenedText ? params.form.chatOpenedText ? params.form.chatOpenedText : 'Login request received and approved' : '',
    invalidEmailAddressText: showInvalidEmailAddressText ? params.form.invalidEmailAddressText ? params.form.invalidEmailAddressText : 'There are invalid characters in your email address. You will not be able to receive a transcript' : '',
    successfulReconnectionText: showSuccessfulReconnectionText ? params.form.successfulReconnectionText ? params.form.successfulReconnectionText : 'Successfully reconnected' : '',
    closeRequestText: showCloseRequestText ? params.form.closeRequestText ? params.form.closeRequestText : 'Close request sent' : '',
    connectionClosedText : showConnectionClosedText ? params.form.connectionClosedText ? params.form.connectionClosedText : 'Connection closed, chat has ended' : '',
    connectionErrorText: showConnectionErrorText ? params.form.connectionErrorText ? params.form.connectionErrorTex : 'A connection error has occurred. Check the console for more details' : '',
    closedForMaintenanceText: showClosedForMaintenanceText ? params.form.closedForMaintenanceText ? params.form.closedForMaintenanceText : 'Contact centre is shutting down for maintenance. Please try again later' : '',
    attemptingToReconnectText: showAttemptingToReconnectText ? params.form.attemptingToReconnectText ? params.form.attemptingToReconnectText : 'Attempting to reconnect...' : '',
    unableToReconnectText: showUnableToReconnectText ? params.form.unableToReconnectText ? params.form.unableToReconnectTex : 'Unable to reconnect, chat has ended' : '',
    panelCloseText: showPanelCloseText ? params.form.panelCloseText ? params.form.panelCloseText : 'The panel will close automatically in {0} seconds' : '',
    errorOccurredText: showErrorOccurredText ? params.form.errorOccurredText ? params.form.errorOccurredText : 'Error code {0} occurred ({1})' : '',

    // placeholder for new message. Format is displayName (timestamp): messageText
    newMessageText : '{0} ({1}): {2}',

    agentJoinedMessage: showAgentJoinedMessage ? params.form.agentJoinedMessage ? params.form.agentJoinedMessage : 'An agent has joined the chat' : '',
    agentLeftMessage: showAgentLeftMessage ? params.form.agentLeftMessage ? params.form.agentLeftMessage : 'An agent has left the chat' : '',
    routeCancelText: showRouteCancelText ? params.form.routeCancelText ? params.form.routeCancelText : 'No suitable agents could be found' : '',

    // placeholder for file transfer notifications. {0} is agent's name, {1} is the file name, {2} is the timestamp. {3} is the URL
    fileTransferMessageText: showFileTransferMessageText ? params.form.fileTransferMessageText ? params.form.fileTransferMessageText : '{0} transferred a file ({1}) at {2}. Please visit {3} to download it' : '',

    transferNotificationText: showTransferNotificationText ? params.form.transferNotificationText ? params.form.transferNotificationText : 'Transferring to another agent, please remain in the chat' : '',
	requeueNotificationText: showRequeueNotificationText ? params.form.requeueNotificationText ? params.form.requeueNotificationText : 'Re-queuing chat, please remain in the chat' : '',
    chatbotTransferNotification: showChatbotTransferNotification ? params.form.chatbotTransferNotification ? params.form.chatbotTransferNotification : 'Transferring to a live agent, please remain in the chat' : '',

    // placeholder for page push messages. {0} represents agent display name, {1} is the timestamp
    pagePushMessageText: showPagePushMessageText ? params.form.pagePushMessageText ? params.form.pagePushMessageText : 'Agent sent the following link: ' : '',
    coBrowseMessageText: '{0} initiated a Co-Browsing session at ({1})',
    coBrowseSessionStartedText : "Co-Browsing session initiated",
    coBrowseSessionFinishedText : "Co-Browsing session finished",
    curentTimeIntervalText: "сегодня",
    oldTimeIntervalText: "вчера",

    notifyOfObserve : true,
    nofiyOfCoach : true,
    notifyOfBarge : true,

    // if set to true, the chatbot will not announce its arrival/departure
    suppressChatbotPresence : suppressChatbotPresence,

    // how frequently the pings are sent
    pingTimer : 5000,

    typingTimeout : 10000,
    agentTypingTimeout : 3000,

    // default username for if the customer doesn't enter a name.
    defaultUsername : showDefaultUsername ? params.form.defaultUsername ? params.form.defaultUsername : 'Customer' : '',

    // CSS classes that distinguish customer from agent messages
    writeResponseClassResponse : 'response',
    writeResponseClassSent : 'sent',
    writeResponseClassSystem : 'system',
    writeResponseClassDate : 'date',
    writeResponseClassAgentDate : 'agentDate',
    writeResponseClassChatbot : 'chatbot',
    writeResponseClassCurentTimeInterval : 'curentTimeInterval',
    writeResponseClassOldTimeInterval : 'oldTimeInterval',

    // links to various images
    agentImage : 'images/agent.png',
    agentTypingImage : 'images/agent_typing.png',
    supervisorImage : 'images/supervisor.png',
    supervisorTypingImage : 'images/supervisor_typing.png',

    // lease time in Context Store, measured in hours
    leaseTime : 1,

    requireFirstName: true,
    requireLastName: true,
    requireEmail: true,
    requirePhone: true,
    requireMessage: true,

    // how long should we hold session data after closing/leaving the page
    // this should mtch the refresh/idle timeout in the Administration Utility
    refreshTimeoutSeconds: 60,

    setLeaseTime : function(newLeaseTime) {
        'use strict';
        if (typeof (newLeaseTime) !== 'number' || newLeaseTime > 192) {
            console.warn("Invalid lease time! Resetting to default (1 hour)");
            newLeaseTime = 1;
        }
        chatConfig.leaseTime = newLeaseTime;
    },

    /**
     * Utility function to request the EWT manually. Acts as a wrapper for the config panel.
     */
    requestEwt : function() {
        'use strict';
        ewt.requestEwt();
    },

    showChatPanel : function() {
        'use strict';
        chatUI.showChatPanel();
    },

    initCobrowse : function() {
        'use strict';
        if (window.coBrowse !== undefined) {
            coBrowse.init();
        }
    }

};
